import { ICoreContext } from '@msdyn365-commerce/core-internal';
import { IGetExperlogixStateData } from '.';

// Listing of pages for URL fabrication
const configureItemPage = '/site/pages/ConfigureItem.aspx';
const experlogixParam_Auth = 'Auth';
const experlogixParam_CartId = 'CartId';
const experlogixParam_CartLineId = 'CartLineId';
const experlogixParam_ProductId = 'ProductId';

export function getExperlogixUrl(context: ICoreContext): string {
    return (context.app as any).config.experlogixSiteUrl;
}

// Reconstitute the application root URL for the Web Configurator.
// Example return:  http://localhost:5000/experlogix
export const getExperlogixRootUrl = (anyExperlogixUrl: string) => {
    const url = new URL(anyExperlogixUrl);

    // Ignoring the first character, which is guaranteed to be a slash, find the first
    // slash which terminates the first path element (if present).
    var firstSlashPosition = url.pathname.indexOf('/', 1);

    // Preserve the path up to the first path element, if discovered.
    const path = firstSlashPosition === -1 ? url.pathname : url.pathname.slice(0, firstSlashPosition);

    return `${url.protocol}//${url.hostname}${path}`;
};

export const getExperlogixConfigureItemUrl = (anyExperlogixUrl: string) => `${getExperlogixRootUrl(anyExperlogixUrl)}${configureItemPage}`;

export const assembleUrlParams = (...urlParams: { key: string | undefined; value: string }[]) =>
    urlParams
        .map(p => (p.key !== undefined ? `${encodeURIComponent(p.key)}=${encodeURIComponent(p.value)}` : encodeURIComponent(p.value)))
        .join('&');

export const getExperlogixProductUrl = (experlogixSiteUrl: string, experlogixState: IGetExperlogixStateData, productId: number | null) => {
    let url = getExperlogixConfigureItemUrl(experlogixSiteUrl);
    if (experlogixState.cartLineId) {
        // Launch CPQ directly to an existing Cart Line's configuration
        url = `${url}?${assembleUrlParams(
            { key: experlogixParam_CartId, value: experlogixState.cart.Id },
            { key: experlogixParam_CartLineId, value: experlogixState.cartLineId },
            { key: experlogixParam_Auth, value: experlogixState.authNonce }
        )}`;
    } else {
        // Launch CPQ to create a new Configuration for the Cart
        url = `${url}?${assembleUrlParams(
            { key: experlogixParam_CartId, value: experlogixState.cart.Id },
            { key: experlogixParam_ProductId, value: productId?.toString() ?? '' },
            { key: experlogixParam_Auth, value: experlogixState.authNonce }
        )}`;
    }
    return url;
};
